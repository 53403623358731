import React, { useState, useCallback, useMemo } from 'react'
import { SelectBox } from 'devextreme-react/select-box'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import utilServices from 'app/services/util-services'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import inventoryService from 'app/services/pages/store/inventory/inventoryServices'
import { DateBox } from 'devextreme-react/date-box'

const InventoryGridFilter = ({ mutation, display, filterIconColor }) => {
    let initialValues = {
        startDate: null,
        endDate: null,
        inventoryNo: '',
        inventoryName: '',
        barCode: '',
    }

    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [filter, setFilter] = useState({ categoryId: null })

    const handleError = useCallback((error) => {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
            })
        }
    })

    const inventoryCategoriesLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await inventoryService.getInventoryCategoriesLookupList({ categoryName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [inventoryService]
    )

    const inventoryCategoriesLookupById = useCallback(
        async (key) => {
            try {
                return await inventoryService.getInventoryCategoriesLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [inventoryService]
    )

    const inventoryCategoryStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'inventoryCategoryId',
                    load: async (loadOptions) => {
                        let searchTerm = loadOptions.searchValue || ''
                        return await inventoryCategoriesLookupList(searchTerm)
                    },
                    byKey: (key) => {
                        if (key === '') return utilServices.emptyList()
                        return inventoryCategoriesLookupById(key)
                    },
                    errorHandler: (e) => {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const onFormSubmit = async (data) => {
        const startDateFormatted = data.startDate ? new Date(data.startDate).toISOString() : null
        const endDateFormatted = data.endDate ? new Date(data.endDate).toISOString() : null
        const formattedData = {
            startDate: startDateFormatted ? startDateFormatted.toFilterStartDate() : null,
            endDate: endDateFormatted ? endDateFormatted.toFilterEndDate() : null,
            inventoryNo: data.inventoryNo,
            inventoryName: data.inventoryName,
            barcode: data.barCode,
            ...filter,
        }
        await mutation.mutate(formattedData)
    }

    const onFilterSubmit = async (data, { setSubmitting }) => {
        setSubmitting(true)
        await onFormSubmit(data)
        setSubmitting(false)
    }

    const handleClear = () => {
        filterIconColor(false)
        setFilter({ categoryId: null })
    }

    const onCategorySelectionChanged = (value) => {
        setFilter({ ...filter, categoryId: value?.inventoryCategoryId })
    }

    return (
        <Formik initialValues={initialValues} onReset={handleClear} onSubmit={onFilterSubmit}>
            {({ isSubmitting, setFieldValue, resetForm, values }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick
                        noWrapper
                        id='filterBody'
                        variant={'primary'}
                        sx={{ display: display, borderRadius: 0 }}>
                        <Divider />
                        <Div style={{ display: 'flex', margin: '0% 1.5% 1.5% 1.5%', flexWrap: 'wrap' }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Start Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <DateBox
                                        type='date'
                                        onValueChanged={(e) => setFieldValue('startDate', e.value)}
                                        value={values.startDate}
                                        style={{ width: 220 }}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>End Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <DateBox
                                        type='date'
                                        onValueChanged={(e) => setFieldValue('endDate', e.value)}
                                        value={values.endDate}
                                        style={{ width: 220 }}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Inventory No</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='inventoryNo' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Inventory Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='inventoryName' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Barcode</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='barCode' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Category</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={inventoryCategoryStore}
                                        displayExpr={'categoryName'}
                                        value={filter.categoryId}
                                        searchEnabled={true}
                                        onValueChange={onCategorySelectionChanged}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        onClick={() => filterIconColor(true)}
                                        variant={'contained'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        variant={'contained'}
                                        onClick={handleClear}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default InventoryGridFilter
