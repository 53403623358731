import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import TabPanel, { Item } from 'devextreme-react/tab-panel'
import SupplierGrid from './components/suppliers/SupplierGrid'
import ApprovedOrders from './components/orders/ApprovedOrders'
import DraftOrder from './components/orders/DraftOrders/DraftOrders'

function Shipments() {
    const navigate = useNavigate()
    const { tabTitle } = useParams()
    let selectedTabIndex = 0

    function currentTab() {
        switch (tabTitle) {
            case 'approvedorders':
                return (selectedTabIndex = 0)
            case 'draftorders':
                return (selectedTabIndex = 1)
            case 'suppliers':
                return (selectedTabIndex = 2)
            default:
                return (selectedTabIndex = 0)
        }
    }
    currentTab()
    const onTabSelectionChanged = (e) => {
        const activeTab = e.addedItems[0].title.toLowerCase().replace(' ', '')
        navigate(`/store/shipments/${activeTab}`)
    }
    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'primary'}>
                        Shipments
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <TabPanel onSelectionChanged={onTabSelectionChanged} selectedIndex={selectedTabIndex}>
                    <Item title='Approved Orders'>
                        <ApprovedOrders />
                    </Item>
                    <Item title='Draft Orders'>
                        <DraftOrder />
                    </Item>
                    <Item title='Suppliers'>
                        <SupplierGrid />
                    </Item>
                </TabPanel>
            </JumboCardQuick>
        </React.Fragment>
    )
}
export default Shipments
